import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../api";

// List service
export const quotationListService = createAsyncThunk(
  "quotationList",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/quotation?status=${
        bodyParams?.type || ""
      }&per_page=${bodyParams.per_page}&
		search=${bodyParams?.search}&page=${bodyParams.page}&from_date=${
        bodyParams.from_date
      }&to_date=${bodyParams.to_date}&sort=${
        bodyParams?.sort || "desc"
      }&sort_column=${bodyParams?.sort_column || ""}&customer_id=${
        bodyParams?.customer_id || ""
      }`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const quotationViewService = createAsyncThunk(
  "quotationView",
  async (params, thunkAPI) => {
    try {
      const response = await API.get(`/quotation/${params?.id}`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const quotationCreateService = createAsyncThunk(
  "quotationCreate",
  async (data, thunkAPI) => {
    try {
      const response = await API.post(`/quotation`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const quotationUpdateService = createAsyncThunk(
  "quotationUpdate",
  async (params, thunkAPI) => {
    try {
      const response = await API.post(`/quotation/${params?.id}`, params, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const quotationDeleteService = createAsyncThunk(
  "quotationDelete",
  async (params, thunkAPI) => {
    try {
      const response = await API.delete(`/quotation/${params?.id}`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const quotationStatusUpdateService = createAsyncThunk(
  "QuotationStatusUpdate",
  async (params, thunkAPI) => {
    try {
      const response = await API.post(
        `/quotation/action-update/${params?.id}?action=${params?.action}`,
        params
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const generateQuotationSingleDeleteService = createAsyncThunk(
  "generateQuotationSingleDeleteService",
  async ({ params, ...data }, thunkAPI) => {
    try {
      const response = await API.post(
        `/quotation-collaboration/action-update/${data?.quotation_id}`,
        {
          data,
        },
        {
          params,
        }
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const generateQuotationUpdateService = createAsyncThunk(
  "generateQuotationUpdateService",
  async ({ params = {}, ...formData }, thunkAPI) => {
    try {
      const response = await API.post(
        `/quotation-collaboration/action-update/${formData?.quotation_id}`,
        formData,
        {
          params,
        }
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const generateQuotationService = createAsyncThunk(
  "generateQuotationService",
  async (formData, thunkAPI) => {
    try {
      const response = await API.post(`/quotation-collaboration`, formData);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const generateQuotationViewService = createAsyncThunk(
  "generateQuotationViewService",
  async (genarateId, thunkAPI) => {
    try {
      const response = await API.get(`/quotation-collaboration/${genarateId}`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const generateQuotationDeleteService = createAsyncThunk(
  "generateQuotationDeleteService",
  async (data, thunkAPI) => {
    try {
      const response = await API.delete(
        `/quotation-collaboration/${data?.id}`,
        {
          data,
        }
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const generateQuotationList = createAsyncThunk(
  "generateQuotationList",
  async (params, thunkAPI) => {
    try {
      const response = await API.get(`/quotation-collaboration`, {
        params,
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const mailactionUpdateService = createAsyncThunk(
  "mailactionUpdateService",
  async ({ params = {}, ...formData }, thunkAPI) => {
    try {
      const response = await API.post(
        `/mailaction/${formData?.quotation_id}`,
        formData,
        {
          params,
        }
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
